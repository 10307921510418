<template>
  <v-app>
    <notifications
      width="auto"
      style="margin-top:70px; margin-right: 12px;"
    >
      <template v-slot:body="{ item, close }">
        <v-alert
          dense
          border="right"
          colored-border
          elevation="24"
          min-width="500"
          :type="item.type"
          :dismissible="!item.timer"
          @input="close"
          @click="() => {
            if (item.timer) close();
          }"
        >
          <div class="title">
            {{ item.title }}
          </div>
          <div>{{ item.text }}</div>
        </v-alert>
      </template>
    </notifications>
    <v-app-bar
      app
      flat
      class="pa-0 white"
    >
      <v-row no-gutters>
        <v-col
          class="pa-0 ps-0"
          cols="2"
          style="min-width: 300px;"
        >
          <v-container
            fill-height
            class="py-0"
          >
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  class="title"
                  data-cy="dashboard_title"
                >
                  RBA Essential
                </v-list-item-title>
                <v-list-item-subtitle style="text-transform: uppercase;">
                  {{ organization_name }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-container>
        </v-col>
        <v-spacer />
        <v-col class="align-self-center pa-3 text-right">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                depressed
                text
              >
                <v-icon left>
                  mdi-web
                </v-icon>{{ $i18n.locale == 'en' ? 'ENG' : '繁體' }}
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item @click="loadLanguageAsync('en')">
                <v-list-item-title>ENG</v-list-item-title>
              </v-list-item>
              <v-list-item @click="loadLanguageAsync('zh-Hant')">
                <v-list-item-title>繁體</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu
            :close-on-content-click="false"
            :nudge-width="0"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                depressed
                text
                class="ml-2"
                data-cy="acc_detail"
              >
                <v-icon left>
                  mdi-account-circle
                </v-icon>
                {{ username }}
              </v-btn>
            </template>

            <v-card>
              <v-list>
                <v-list-item>
                  <v-list-item-avatar>
                    <!-- <img src="" alt=""> -->
                    <v-icon size="40">
                      mdi-account-circle
                    </v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>{{ username }}</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ role }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <v-divider />

              <v-list dense>
                <v-list-item dense>
                  <v-list-item-title
                    data-cy="acc_org"
                  >
                    {{ $t('Organization') }}
                  </v-list-item-title>
                  <v-list-item-subtitle>{{ organization_name }}</v-list-item-subtitle>
                </v-list-item>
                <v-list-item dense>
                  <v-list-item-title>{{ $t('Services') }}</v-list-item-title>
                  <v-list-item-subtitle>{{ access_menus }}</v-list-item-subtitle>
                </v-list-item>
              </v-list>

              <v-card-actions>
                <v-spacer />
                <v-btn
                  text
                  @click="dialog_logout_confirmation=true"
                  color="error"
                  data-cy="acc_logout_btn"
                >
                  <v-icon left>
                    mdi-logout
                  </v-icon>
                  {{ $t('menus.logout.title') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-col>
      </v-row>
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main>
      <!-- Provides the application the proper gutter -->
      <v-container fluid>
        <v-row class="flex-wrap">
          <!-- <v-col cols="12" class="col col-2 col-lg-3 col-sm-4 col-xl-2" style="max-width:300px;"> -->
          <v-col
            id="menuCol"
            @mouseover="hover=true"
            @mouseleave="hover=false"
          >
            <v-navigation-drawer
              id="sideMenuBar"
              :expand-on-hover="checkScreenSize"
              height="auto"
              :absolute="checkScreenSize"
              permanent
              :class="checkScreenSize && hover ? 'elevation-24' : 'elevation-2'"
            >
              <v-list
                style="width: 100%;"
                dense
              >
                <!-- <v-subheader><v-icon left>mdi-account-circle</v-icon>{{ username }}</v-subheader> -->

                <!-- <v-divider></v-divider> -->

                <v-list-item
                  :to="{ name: 'Dashboard' }"
                  no-action
                  exact
                  data-cy="to_Dashboard_btn"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-view-dashboard</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ $t('menus.dashboard.title') }}</v-list-item-title>
                </v-list-item>

                <v-list-group
                  prepend-icon="mdi-bank"
                  @click="/*$router.push({ name: 'Companies' })*/"
                  data-cy="company_btn_grp"
                >
                  <template v-slot:activator>
                    <v-list-item-title>{{ $t('menus.company.title') }}</v-list-item-title>
                  </template>

                  <v-list-item
                    :to="{ name: 'Companies' }"
                    no-action
                    sub-group
                    exact
                    data-cy="to_Companies_btn"
                  >
                    <v-list-item-title v-if="!checkScreenSize || hover">
                      {{ $t('menus.company.menus.list.title') }}
                    </v-list-item-title>
                    <v-list-item-icon>
                      <v-icon>mdi-table-large</v-icon>
                    </v-list-item-icon>
                  </v-list-item>

                  <v-list-item
                    :to="{ name: 'CompanyCreate' }"
                    no-action
                    sub-group
                    exact
                    data-cy="to_CompanyCreate_btn"
                  >
                    <v-list-item-title v-if="!checkScreenSize || hover">
                      {{ $t('menus.company.menus.new.title') }}
                    </v-list-item-title>
                    <v-list-item-icon>
                      <v-icon>mdi-bank-plus</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list-group>

                <v-list-group
                  prepend-icon="mdi-account-group"
                  data-cy="naturalPerson_btn_grp"
                >
                  <template v-slot:activator>
                    <v-list-item-title>{{ $t('menus.person.title') }}</v-list-item-title>
                  </template>

                  <v-list-item
                    :to="{ name: 'NaturalPerson' }"
                    no-action
                    sub-group
                    exact
                    data-cy="to_NaturalPerson_btn"
                  >
                    <v-list-item-title v-if="!checkScreenSize || hover">
                      {{ $t('menus.person.menus.list.title') }}
                    </v-list-item-title>
                    <v-list-item-icon>
                      <v-icon>mdi-table-account</v-icon>
                    </v-list-item-icon>
                  </v-list-item>

                  <v-list-item
                    :to="{ name: 'NaturalPersonCreate' }"
                    no-action
                    sub-group
                    exact
                    data-cy="to_NaturalPersonCreate_btn"
                  >
                    <v-list-item-title v-if="!checkScreenSize || hover">
                      {{ $t('menus.person.menus.new.title') }}
                    </v-list-item-title>
                    <v-list-item-icon data-cy="create_natural_person">
                      <v-icon>mdi-account-multiple-plus</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list-group>

                <!-- <v-list-item
                  link
                  :to="{ name: 'Transaction' }"
                  v-if="access_menus.indexOf('TRANSACTION') != -1"
                  data-cy="to_Transaction_btn"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-currency-usd</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ $t('menus.transaction.title') }}</v-list-item-title>
                </v-list-item> -->

                <v-list-item
                  link
                  :to="{ name: 'Transaction2' }"
                  v-if="access_menus.indexOf('TRANSACTION') != -1"
                  data-cy="to_Transaction2_btn"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-currency-usd</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    {{ $t('menus.transaction.title') }}
                    <!-- <v-icon color="error">
                      mdi-new-box
                    </v-icon> -->
                  </v-list-item-title>
                </v-list-item>

                <v-list-item
                  link
                  :to="{ name: 'AlertsHistory' }"
                  data-cy="to_AlertHistory_btn"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-history</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ $t('menus.alert.title') }}</v-list-item-title>
                </v-list-item>

                <v-list-item
                  link
                  :to="{ name: 'AlertCasePool' }"
                  data-cy="to_AlertCasePool_btn"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-history</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    {{ $t('menus.alertCasePool.title') }}
                    <v-icon color="error">
                      mdi-new-box
                    </v-icon>
                  </v-list-item-title>
                </v-list-item>

                <v-list-group
                  prepend-icon="mdi-account-search"
                  data-cy="name_scanning_grp"
                >
                  <template v-slot:activator>
                    <v-list-item-title>{{ $t('menus.screen.title') }}</v-list-item-title>
                  </template>

                  <!-- <v-list-item
                    :to="{ name: 'SingleScan' }"
                    no-action
                    sub-group
                    exact
                    data-cy="to_SingleScan_btn"
                  >
                    <v-list-item-title v-if="!checkScreenSize || hover">
                      {{ $t('menus.screen.menus.single.title') }}
                    </v-list-item-title>
                    <v-list-item-icon>
                      <v-icon>mdi-magnify</v-icon>
                    </v-list-item-icon>
                  </v-list-item> -->

                  <v-list-item
                    :to="{ name: 'SingleScan2' }"
                    no-action
                    sub-group
                    exact
                    data-cy="to_OnboardScan_btn"
                  >
                    <v-list-item-title v-if="!checkScreenSize || hover">
                      {{ $t('menus.screen.menus.onboarding.title') }}
                      <!-- <v-icon color="error">
                        mdi-new-box
                      </v-icon> -->
                    </v-list-item-title>
                    <v-list-item-icon>
                      <v-icon>mdi-magnify</v-icon>
                    </v-list-item-icon>
                  </v-list-item>

                  <v-list-item
                    :to="{ name: 'BatchScreening' }"
                    no-action
                    sub-group
                    exact
                    data-cy="to_BatchScreening_btn"
                  >
                    <v-list-item-title v-if="!checkScreenSize || hover">
                      {{ $t('menus.screen.menus.batch.title') }}
                    </v-list-item-title>
                    <v-list-item-icon>
                      <v-icon>mdi-memory</v-icon>
                    </v-list-item-icon>
                  </v-list-item>

                  <v-list-item
                    :to="{ name: 'BatchScreeningLog' }"
                    no-action
                    sub-group
                    exact
                    data-cy="to_BatchScreeningLog_btn"
                  >
                    <v-list-item-title v-if="!checkScreenSize || hover">
                      {{ $t('menus.screen.menus.log.title') }}
                    </v-list-item-title>
                    <v-list-item-icon>
                      <v-icon>mdi-history</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list-group>

                <v-list-item
                  link
                  :to="{ name: 'eKYCAlliance' }"
                  v-if="access_menus.indexOf('EKYC') != -1"
                  data-cy="to_eKYCAlliance_btn"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-check-decagram</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ $t('menus.ekyc.title') }}</v-list-item-title>
                </v-list-item>

                <v-list-item
                  link
                  :to="{ name: 'Import' }"
                  data-cy="to_Import_btn"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-database-import</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ $t('menus.import.title') }}</v-list-item-title>
                </v-list-item>

                <v-list-item
                  link
                  :to="{ name: 'AuditLog' }"
                  data-cy="to_AuditLog_btn"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-file-clock</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title> {{ $t('menus.auditlog.title') }} </v-list-item-title>
                </v-list-item>

                <!-- <v-list-item link :to="{ name: 'Export' }">
								<v-list-item-icon>
									<v-icon>mdi-database-export</v-icon>
								</v-list-item-icon>
								<v-list-item-title>{{ $t('menus.export.title') }}</v-list-item-title>
							</v-list-item> -->

                <!-- <v-list-item link>
								<v-list-item-icon>
									<v-icon>mdi-file-excel</v-icon>
								</v-list-item-icon>
								<v-list-item-title>Reports</v-list-item-title>
							</v-list-item>

							<v-list-item link>
								<v-list-item-icon>
									<v-icon>mdi-backup-restore</v-icon>
								</v-list-item-icon>
								<v-list-item-title>Backup</v-list-item-title>
							</v-list-item> -->

                <v-divider />
                <v-list-item
                  link
                  @click="dialog_logout_confirmation=true"
                  data-cy="menu_logout_btn"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-logout</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ $t('menus.logout.title') }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-navigation-drawer>
          </v-col>
          <v-col style="transform: scale(1);">
            <!-- <transition name="fade"> -->
            <router-view />
            <!-- </transition> -->
          </v-col>
        </v-row>

        <!-- Logout Confirmation Dialog -->
        <v-dialog
          v-model="dialog_logout_confirmation"
          persistent
          max-width="350"
        >
          <v-card
            data-cy="logout_conf_dialog"
          >
            <v-card-title>{{ $t('dialogs.logout_confirm.title') }}</v-card-title>
            <v-card-text>{{ $t('dialogs.logout_confirm.text') }}</v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="error"
                text
                :loading="dialog_logout_confirmation_loading"
                @click="logout"
                data-cy="logout_conf_btn"
              >
                {{ $t('dialogs.logout_confirm.buttons.yes') }}
              </v-btn>
              <v-btn
                color="darken-1"
                text
                :disabled="dialog_logout_confirmation_loading"
                @click="dialog_logout_confirmation = false"
              >
                {{ $t('dialogs.logout_confirm.buttons.no') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>
    </v-main>

    <v-menu
      transition="slide-x-transition"
      v-if="isNewVersionReleased"
      v-model="showReleaseDialog"
      :close-on-click="false"
      :close-on-content-click="false"
      top
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          small
          fab
          v-bind="attrs"
          v-on="on"
          :style="{
            left: 0,
            position: 'fixed',
            bottom: '36px',
            margin: '12px'
          }"
          :class="{
            'minimized': !showReleaseDialog
          }"
        >
          <v-icon color="red">
            mdi-autorenew
          </v-icon>
        </v-btn>
      </template>
      <v-card
        elevation="24"
      >
        <v-card-title class="pr-12">
          {{ $t('dialogs.new_version_released.title') }}
        </v-card-title>

        <v-card-text class="pr-12">
          {{ $t('dialogs.new_version_released.text') }}
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-dialog
            persistent
            max-width="450"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                text
                v-bind="attrs"
                v-on="on"
              >
                <v-icon left>
                  mdi-autorenew
                </v-icon>
                {{ $t('dialogs.new_version_released.buttons.update') }}
              </v-btn>
            </template>
            <template v-slot:default="dialog">
              <v-card
                class="mx-auto"
              >
                <v-card-title>
                  {{ $t('dialogs.new_version_released.confirm.title') }}
                </v-card-title>
                <v-card-text>
                  {{ $t('dialogs.new_version_released.confirm.text') }}
                </v-card-text>
                <v-divider />
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="red"
                    text
                    @click="getNewApplicationVersion()"
                  >
                    {{ $t('dialogs.new_version_released.confirm.buttons.yes') }}
                  </v-btn>
                  <v-btn
                    text
                    @click="dialog.value = false"
                  >
                    {{ $t('dialogs.new_version_released.confirm.buttons.cancel') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
          <v-btn
            text
            @click="showReleaseDialog = false"
          >
            {{ $t('dialogs.new_version_released.buttons.later') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>

    <v-footer
      app
      class="blue--text"
    >
      {{ $t('footer.copyright') }}
      <v-spacer />
      {{ version }}
      <!-- © Copyright {{ new Date().getFullYear() }} TESS ASIA. All Rights Reserved. -->
    </v-footer>
  </v-app>
</template>

<script>
// import Notification from '@/components/common/Notification'
import { mapActions, mapGetters, mapState } from 'vuex'
export default {
  components: {
    // Notification
  },
  watch: {
    is_logged_in () {
      this.redirectIfNeeded()
    }
  },
  computed: {
    ...mapGetters('auth', [
      'is_logged_in',
      'username'
    ]),
    ...mapState('auth', [
      'organization_name',
      'role',
      'access_menus'
    ]),
    checkScreenSize () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xl':
          return false
        case 'lg':
        case 'md':
        default :
          return true
      }
    },
    version: {
      get () {
        return `App Ver. ${process.env.VUE_APP_VERSION} (${this.versionInfo.server})`
      }
    }
  },
  data: () => ({
    dialog_logout_confirmation: false,
    dialog_logout_confirmation_loading: false,
    hover: false,
    versionChecker: null,
    versionInfo: {
      server: '...',
      client: ''
    },
    isNewVersionReleased: false,
    showReleaseDialog: false
  }),
  methods: {
    ...mapActions('auth', [
      'logout'
    ]),
    getVersionInfo () {
      this.$store.dispatch('setting/fetchLatestApplicationVersion').then(results => {
        if (results.data.client !== process.env.VUE_APP_VERSION && this.versionInfo.client !== results.data.client) {
          // compare version numbers
          const currentVersion = process.env.VUE_APP_VERSION.split('.')
          const latestVersion = results.data.client.split('.')
          for (let i = 0; i < Math.min(currentVersion.length, latestVersion.length); i++) {
            if (parseInt(currentVersion[i]) < parseInt(latestVersion[i])) {
              this.isNewVersionReleased = true
              this.$nextTick(() => {
                this.showReleaseDialog = true
              })
              break
            }
          }
        }
        this.versionInfo = results.data
      })
    },
    getNewApplicationVersion () {
      window.location.reload(true)
    },
    redirectIfNeeded () {
      if (!this.is_logged_in) {
        this.$router.replace({ name: 'Login' })
      }
    }
  },
  beforeCreate () {
    // Fetch Latest Conutry List from AMLWSREST
    this.$store.dispatch('setting/fetchCountryList')
  },
  mounted () {
    this.redirectIfNeeded()

    this.$store.dispatch('auth/fetchProfile')

    this.getVersionInfo()
    this.versionChecker = setInterval(() => {
      this.getVersionInfo()
    }, 600000) // 10 minutes
  },
  beforeDestroy () {
    clearInterval(this.versionChecker)
  }
}
</script>

<style>
.v-toolbar__content { padding: 0 !important; }

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
#sideMenuBar{
    max-width: 280px;
    border-radius: 5px;
    position: relative;
}

#menuCol { transition: max-width 250ms ease-in-out; }
/* Responsive Layout */
@media (min-width:960px ) {
    #menuCol{
       max-width: 71px;
    }
    /*#sideMenuBar{
        margin-top:24px;
        margin-left:10px;
    }*/
}

@media (min-width:1904px) {
     #menuCol{
       max-width: 280px;
    }
    /*#sideMenuBar{
        position: relative;
        margin: 0px;
    }*/
}

button.v-btn.v-btn--contained.v-btn--fab.v-btn--round.theme--light.v-size--small.minimized:after {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  content: "";
  box-sizing: content-box;
  top: 0;
  left: 0;
  padding: 0;
  z-index: -1;
  opacity: 0;
  display: inline-block;
  animation: sonarEffect 1.3s ease-out 75ms infinite;
}

@-webkit-keyframes sonarEffect {
  0% {
    opacity: 0.3;
  }
  40% {
    opacity: 0.5;
    box-shadow: 0 0 0 2px rgba(255,255,255,0.1), 0 0 10px 10px #fff, 0 0 0 10px rgba(255,255,255,0.5);
  }
  100% {
    box-shadow: 0 0 0 2px rgba(255,255,255,0.1), 0 0 10px 10px #fff, 0 0 0 10px rgba(255,255,255,0.5);
    -webkit-transform: scale(1.5);
    opacity: 0;
  }
}
@-moz-keyframes sonarEffect {
  0% {
    opacity: 0.3;
  }
  40% {
    opacity: 0.5;
    box-shadow: 0 0 0 2px rgba(255,255,255,0.1), 0 0 10px 10px #fff, 0 0 0 10px rgba(255,255,255,0.5);
  }
  100% {
    box-shadow: 0 0 0 2px rgba(255,255,255,0.1), 0 0 10px 10px #fff, 0 0 0 10px rgba(255,255,255,0.5);
    -moz-transform: scale(1.5);
    opacity: 0;
  }
}
@keyframes sonarEffect {
  0% {
    opacity: 0.3;
  }
  40% {
    opacity: 0.5;
    box-shadow: 0 0 0 2px rgba(255,255,255,0.1), 0 0 10px 10px #fff, 0 0 0 10px rgba(255,255,255,0.5);
  }
  100% {
    box-shadow: 0 0 0 2px rgba(255,255,255,0.1), 0 0 10px 10px #fff, 0 0 0 10px rgba(255,255,255,0.5);
    transform: scale(1.5);
    opacity: 0;
  }
}

</style>
